@import url(https://fonts.googleapis.com/css?family=Jura&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  font-family: "Jura", sans-serif;
  background: black;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  width: 100vw; }
  .App header {
    width: 100vw;
    height: 80px;
    border-bottom: 16px solid #931b1b; }
  .App h1 {
    color: white;
    padding: 32px 0 0 0;
    margin: 0; }
  .App h2 {
    color: #c51010;
    padding: 0;
    margin: 0;
    font-size: 32px; }
  .App p {
    color: white;
    font-size: 14px;
    margin: 8px 0; }
  .App .content {
    display: flex;
    justify-content: space-between;
    padding: 0 120px;
    color: white; }
    @media only screen and (max-width: 800px) {
      .App .content {
        padding: 0 60px; } }
    @media only screen and (max-width: 600px) {
      .App .content {
        padding: 0 30px; } }
    .App .content img.logo {
      height: 96px;
      -webkit-transform: translateY(50px);
              transform: translateY(50px); }
    .App .content button {
      position: absolute;
      -webkit-transform: translateY(68px);
              transform: translateY(68px);
      font-weight: bold;
      padding: 12px;
      border-radius: 4px;
      background: black;
      color: #931b1b;
      text-transform: uppercase;
      background: -webkit-gradient(linear, left bottom, left top, from(#080808), color-stop(35%, #121212), to(#464646));
      background: -webkit-linear-gradient(bottom, #080808 0%, #121212 35%, #464646 100%);
      background: linear-gradient(0deg, #080808 0%, #121212 35%, #464646 100%);
      border: none;
      box-shadow: inset 0 0 3px #7e7e7e;
      box-shadow: 0px 2px 9px -2px #931b1b; }
  .App .copy {
    background: #262525;
    flex-direction: column;
    padding-bottom: 64px;
    min-height: calc(100vh - 80px);
    justify-content: flex-start; }

