$red: #931b1b;
@import url("https://fonts.googleapis.com/css?family=Jura&display=swap");

.App {
  font-family: "Jura", sans-serif;
  background: black;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  width: 100vw;
  header {
    width: 100vw;
    height: 80px;
    border-bottom: 16px solid $red;
  }
  h1 {
    color: white;
    padding: 32px 0 0 0;
    margin: 0;
  }
  h2 {
    color: #c51010;
    padding: 0;
    margin: 0;
    font-size: 32px;
  }
  p {
    color: white;
    font-size: 14px;
    margin: 8px 0;
  }
  .content {
    display: flex;
    justify-content: space-between;
    padding: 0 120px;
    color: white;
    @media only screen and (max-width: 800px) {
      padding: 0 60px;
    }
    @media only screen and (max-width: 600px) {
      padding: 0 30px;
    }
    img.logo {
      height: 96px;
      transform: translateY(50px);
    }
    button {
      position: absolute;
      transform: translateY(68px);
      font-weight: bold;
      padding: 12px;
      border-radius: 4px;
      background: black;
      color: $red;
      text-transform: uppercase;
      background: linear-gradient(
        0deg,
        rgba(8, 8, 8, 1) 0%,
        rgba(18, 18, 18, 1) 35%,
        rgba(70, 70, 70, 1) 100%
      );
      border: none;
      //inner glow
      -moz-box-shadow: inset 0 0 3px #e7e7e7;
      -webkit-box-shadow: inset 0 0 3px #e7e7e7;
      box-shadow: inset 0 0 3px #7e7e7e;
      //drop shadow
      -moz-box-shadow: 0px 2px 9px -2px $red;
      -webkit-box-shadow: 0px 2px 9px -2px $red;
      box-shadow: 0px 2px 9px -2px $red;
    }
  }
  .copy {
    background: #262525;
    flex-direction: column;
    padding-bottom: 64px;
    min-height: calc(100vh - 80px;);
    justify-content: flex-start;
  }
}
